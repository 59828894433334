var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./modalAuth.module.css";
import { useMediaQuery } from "react-responsive";
import style from "./modalAuth.module.css";
import { ModalAuthForm } from "../../Helpers/Forms/ModalAuthForm/ModalAuthForm";
import close_icon from "../../../assets/img/close_modal_icon.png";
import { useSelector } from "react-redux";
export var ModalAuth = function (props) {
    var onClose = props.onClose;
    var isMobile = useMediaQuery({
        query: "(max-width: 700px)",
    });
    var logo = useSelector(function (state) { var _a, _b; return (_b = (_a = state.menu.results) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.logo; });
    var handleCloseModal = function () {
        onClose();
    };
    return (_jsxs("div", __assign({ className: style.modalAuth, onClick: function (e) { return e.stopPropagation(); } }, { children: [_jsxs("div", __assign({ className: style.modalAuthLogo }, { children: [_jsx("div", __assign({ className: style.modalAuthLogoWrapper }, { children: _jsx("img", { src: logo || "https://itnews.pro/media/files/section/logo.svg", alt: "logo form", className: style.modalAuthLogoImg }) })), isMobile ? (_jsx("div", __assign({ onClick: function () { return handleCloseModal(); }, className: style.modalAuthClose }, { children: _jsx("img", { className: style.modalAuthCloseIcon, src: close_icon, alt: "close icon" }) }))) : null] })), _jsx(ModalAuthForm, {}), isMobile ? null : (_jsx("img", { onClick: function () { return handleCloseModal(); }, className: style.modalAuthCloseIcon, src: close_icon, alt: "close icon" }))] })));
};
